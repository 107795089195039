import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { TextBox } from "../components/text-box"
import { Card } from "../components/card"

const P = ({ children }) => (
  <p onMouseDown={e => false} ref={e => false}>
    {children}
  </p>
)

const Impressum = () => (
  <Layout noHeader>
    <Container>
      <Link to="/" className="link">
        &#8249; Zurück zum Vergleich
      </Link>
      <Card title="Impressum" className="mt-5">
        <P>
          <strong>Kontakt:</strong>
        </P>
        <P>
          Oliver Haag
          <br />
          St. Johanner Str. 41-43
          <br />
          66111 Saarbrücken
        </P>
        <P>
          <strong>E-Mail:</strong>
        </P>
        <P>oliver_haag@gmx.net</P>
      </Card>
    </Container>
  </Layout>
)

export default Impressum
