import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Nav, Container, Row, Col } from "react-bootstrap"
import Link from "./link"

const TextSeparator = () => (
  <span
    style={{
      fontSize: "1.2rem",
      color: "transparent !important",
      flexBasis: "100%",
      height: "0",
    }}
  >
    {" | "}
  </span>
)

const Footer = () => {

  return (
    <footer className="mt-5 mb-2">
      <Container fluid>
        <div
          className="d-flex justify-content-center text-uppercase"
          style={{
            fontSize: "1.0rem !important",
          }}
        >
          <Row>
            <Col className="footer-text" style={{display: "contents"}}>© {new Date().getFullYear()}, Oliver Haag</Col>
            <Col className="footer-text" style={{display: "contents"}}>
              <Link to="/datenschutz">Datenschutz</Link>
            </Col>
            <Col className="footer-text" style={{display: "contents"}}>
              <Link to="/impressum">Impressum</Link>
            </Col>
            <Col className="footer-text" style={{display: "contents"}}>
              <Link to="https://su-kahpunkt.de/">
                Design made by su-kahpunkt.de
              </Link>
            </Col>
          </Row>
          {/* <TextSeparator />
            <TextSeparator />
            <br className="rwd-break"/>
            <TextSeparator /> */}

          <div>
            {/* <span>© {new Date().getFullYear()}, Oliver Haag</span>
            <TextSeparator />
            <Link to="/datenschutz">Datenschutz</Link>
            <TextSeparator />
            <Link to="/impressum">Impressum</Link>
            <br className="rwd-break"/>
            <TextSeparator />
            <Link to="https://su-kahpunkt.de/">
              Design made by su-kahpunkt.de
            </Link> */}
          </div>
        </div>
      </Container>
    </footer>
  )
}

const Layout = ({ noHeader = false, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <main style={{ marginTop: "50px" }}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
